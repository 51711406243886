<template>
   <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <div class="navbar-brand mb-0 h1">Bullbullgo ｜ 为套利而生</div>
    </div>
  </nav>
</template>

<script>
export default {
  name:'Nav',
}
</script>